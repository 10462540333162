<template>
  <base-dialog v-model="dialog" :title="title" :max-width="maxWidth">
    <v-container fluid>
      <v-row>
        <v-col cols="auto">
          <slot></slot>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <mf-button color="error" icon="restart_alt" label="Cancelar" @click="closeDialog"></mf-button>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: this.value,
      maxWidth: '700',
      branch: '',
      list: [],
      search: ''
    }
  },
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['accounts'],
        headers: {
          authorization: this.$route.params.id
        }
      }
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close', this.dialog)
      this.$emit('input', false)
    }
  }
}
</script>

<style></style>
