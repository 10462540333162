<template>
  <base-dialog v-model="dialog" title="Importação de arquivos .CSV" :max-width="readedFromCsv ? '1150' : '500'">
    <v-row>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <form enctype="multipart/form-data" class="ma-4">
              <input ref="fileUpload" accept=".csv" type="file" @change="onFileChange" />
            </form>
          </v-col>
          <v-col>
            <v-alert v-if="readedFromCsv === 0" type="error" outlined dense class="ma-4"
              >Não foi possível processar os contatos importados! O arquivo informado não está seguindo o padrão correto. Para poder consultar um exemplo
              <a href="https://mercafacil.getoutline.com/doc/importacao-de-contatos-whatsapp-1kqyRccNCd/insights" target="_blank" rel="noopener noreferrer">
                clique aqui
              </a>
            </v-alert>
            <v-card-title v-if="readedFromCsv">Total de contatos processados: {{ readedFromCsv }}</v-card-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="auto">
        <mf-button
          v-if="readedFromCsv"
          :disabled="!readedFromCsv"
          class="ma-2"
          icon="upload"
          label="Importar contatos processados"
          color="error"
          @click="importFromCsv"
        >
        </mf-button>
      </v-col>
      <v-col cols="auto">
        <mf-button
          v-if="readedFromCsv"
          class="ma-2"
          color="primary"
          icon="plagiarism"
          label="Prévia de contatos processados"
          @click="preview = true"
        ></mf-button>
      </v-col>
      <v-col cols="auto">
        <mf-button v-if="readedFromCsv" class="ma-2" icon="plagiarism" label="Linhas com erro" color="primary" @click="previewErrors = true"></mf-button>
      </v-col>
    </v-row>

    <show-contacts v-if="contacts" v-model="preview" title="Prévia dos contatos importados">
      <v-chip v-for="contact in contacts" :key="contact.index" class="ma-1">{{ `Número: ${contact.number} -  Register: ${contact.register}` }}</v-chip>
    </show-contacts>

    <show-contacts v-if="linesWithErrors" v-model="previewErrors" title="Linhas com erro">
      <v-chip v-for="line in linesWithErrors" :key="line.index" class="ma-1">{{ line }}</v-chip>
    </show-contacts>
  </base-dialog>
</template>

<script>
import { MUTATION_INSERT_CONTACTS_FROM_CSV } from '@/modules/accounts/graphql'
import ShowContacts from './ShowContacts.vue'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    ShowContacts,
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      readedFromCsv: null,
      contacts: [],
      preview: false,
      previewErrors: false,
      linesWithErrors: [],
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
      this.readedFromCsv = null
      this.contacts = []
      this.$refs.fileUpload.value = null
    },
    async onFileChange(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.linesWithErrors = []
        const readed = e.target.result
          .toString()
          .replace('\r', '')
          .split('\n')
          .map((n, index) => {
            const data = n.split(',')

            if (data.length === 2) {
              data[0] = data[0].replace(' ', '')
              data[1] = data[1].replace(' ', '')

              data[0] = data[0].replace('\r', '')
              data[1] = data[1].replace('\r', '')

              let dddNumber = ''
              let dddRegister = ''

              /* caso o numero venha com 10 ou 11 caracteres, não tem o 55,
              o código então o coloca e armazena o DDD */
              if (data[0].length >= 10 && data[0].length <= 11) {
                dddNumber = data[0].substr(0, 2)
                data[0] = `55${data[0]}`
                /* caso ele venha com 12 ou 13 caracteres, já possui o 55
                então apenas armazena o DDD */
              } else if (data[0].length >= 12 && data[0].length <= 13) {
                dddNumber = data[0].substr(2, 2)
                /* caso não venha de 10 a 13 caracteres, esta incorreto */
              } else {
                this.linesWithErrors.push(`${index + 1} - Número do cliente inválido`)
                return null
              }

              if (data[1].length >= 10 && data[1].length <= 11) {
                dddRegister = data[1].substr(0, 2)
                data[1] = `55${data[1]}`
              } else if (data[1].length >= 12 && data[1].length <= 13) {
                dddRegister = data[1].substr(2, 2)
              } else {
                this.linesWithErrors.push(`${index + 1} - Número 'Register' inválido`)
                return null
              }

              /* valida se o primeiro dos 8 dígitos esta é 6,7,8 ou 9
              considerados válidos para números celulares */
              const numberIsCellPhone = !!(data[0].substr(-8, 1) >= 4 && data[0].substr(-8, 1) <= 9)
              const registerIsCellPhone = !!(data[1].substr(-8, 1) >= 4 && data[1].substr(-8, 1) <= 9)

              if (numberIsCellPhone) {
                /* caso o DDD seja maior que 10 e menor que 30, são DDDs pertencentes ao RJ e SP */
                const numberIsRjSp = !!(dddNumber >= 11 && dddNumber <= 29)
                /* caso seja RJ ou SP e possua 12 dígitos, não esta com um nove extra,
                então é inserido */
                // eslint-disable-next-line no-unused-expressions
                numberIsRjSp && data[0].length === 12 ? (data[0] = data[0].substr(0, 4) + '9' + data[0].substr(4)) : null
                /* caso não seja RJ ou SP e possua 13 dígitos, esta com um nove extra,
                então ele é retirado */
                // eslint-disable-next-line no-unused-expressions
                !numberIsRjSp && data[0].length === 13 ? (data[0] = data[0].substr(0, 4) + data[0].substr(5)) : null
              } else {
                this.linesWithErrors.push(`${index + 1} - Número do cliente não esta no formado de celular`)
                return null
              }

              if (registerIsCellPhone) {
                const registerIsRjSp = !!(dddRegister >= 11 && dddRegister <= 29)
                // eslint-disable-next-line no-unused-expressions
                registerIsRjSp && data[1].length === 12 ? (data[1] = data[1].substr(0, 4) + '9' + data[1].substr(4)) : null
                // eslint-disable-next-line no-unused-expressions
                !registerIsRjSp && data[1].length === 13 ? (data[1] = data[1].substr(0, 4) + data[1].substr(5)) : null
              }

              // Restrição de Register, permitindo apenas números de celular
              /* else {
                this.linesWithErrors.push(`${index + 1} - Número do 'Register' não esta no formado de celular`)
                return null
              } */
              return { number: data[0], register: data[1] }
            } else {
              this.linesWithErrors.push(`${index + 1} - Não possui as duas colunas obrigatórias.`)
              return null
            }
          })
          .filter(n => n !== null)

        this.readedFromCsv = readed.length || 0
        this.contacts = readed || []
      }
      reader.readAsText(file)
    },
    async importFromCsv() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_CONTACTS_FROM_CSV,
          variables: {
            id: this.accountId,
            contacts: this.contacts
          }
        })
        this.$snackbar({ message: 'Contatos inseridos', snackbarColor: '#2E7D32' })
        setTimeout(() => {
          this.$router.go()
        }, 200)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao inserir contatos', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
